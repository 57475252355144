import { FC } from 'react';
import { getLocalizedRedirects, getPagePaths } from '../lib/pages';

import {
  contentful,
  ContentfulRequest,
  LinkMap,
  Reference,
} from '@oresundsbron/api';
import { unwrap } from '@oresundsbron/utilities';
import { DehydratedState } from '@tanstack/react-query';
import { GetStaticProps } from 'next';
import {
  allTranslationKeys,
  findCurrentPage,
  findParentLinkRef,
  findRedirectPage,
  PageType,
  refTypePages,
  ReturnPageComponent,
} from '~/lib/pagegeneration';
import { enrichStaticProps, NotFoundResult } from '~/lib/server/staticProps';
import { isStringArray } from '~/types/typesGuards/isStringArray';
import { contentfulReq } from '../lib/api/server';
import { page } from '../lib/prefetch';

export async function getStaticPaths({ locales }: { locales: string[] }) {
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.SITE_URL === 'https://dev.oresundsbron.com' // Do not prerender anything in dev or development
  ) {
    return {
      paths: [],
      fallback: true,
    };
  }

  try {
    const contentfulRequest = await unwrap<ReturnType<() => ContentfulRequest>>(
      contentfulReq
    );

    const linkMap = await unwrap<Record<string, LinkMap>>(
      contentful.Links.pages({
        preview: false,
        locales,
      })(contentfulRequest)
    );

    const paths = getPagePaths(linkMap) || [];

    return {
      paths,
      fallback: true,
    };
  } catch {
    return {
      paths: [],
      fallback: true,
    };
  }
}

export const getStaticProps = (async ({
  params,
  locale,
  locales,
  preview = false,
  defaultLocale,
}) => {
  if (!locale || !locales || !params || !isStringArray(params.slug)) {
    console.error(`Error inside getStaticProps for page with props of params: ${params},
    locale: ${locale},
    locales ${locales},
    preview ${preview}`);
    return;
  }
  const listLinks = contentful.Links.list({
    preview,
    locales,
  });

  const contentfulRequest = await unwrap<ReturnType<() => ContentfulRequest>>(
    contentfulReq
  );
  const allLinks = await unwrap<Record<string, LinkMap>>(
    listLinks(contentfulRequest)
  );

  const [contentfulPageId, currentPageLink] = findCurrentPage(
    allLinks,
    params,
    locale
  );

  if (!contentfulPageId || !currentPageLink) {
    const redirect = await findRedirectPage(params, locale, defaultLocale);
    if (redirect) {
      return {
        redirect: { destination: redirect.to, permanent: redirect.permanent },
      };
    }
    return { notFound: true } as NotFoundResult;
  }

  const localizedLinks = allLinks[locale];

  const localizedRedirects = getLocalizedRedirects(
    contentfulPageId,
    locale,
    locales,
    allLinks
  );

  const dehydratedState = await unwrap<DehydratedState>(
    page(params.slug, locale, localizedRedirects, localizedLinks)
  );

  const parentLinkRef = findParentLinkRef(currentPageLink, localizedLinks);
  let pageType: Reference = PageType.Page;

  if (parentLinkRef) {
    pageType = parentLinkRef;
  }
  if (currentPageLink.slugs.includes(PageType.ClubGuide)) {
    pageType = PageType.ClubGuide;
  }

  if (currentPageLink.__typename === PageType.PageCorporateFullWidth) {
    pageType = PageType.PageCorporateFullWidth;
  }

  if (currentPageLink.ref && refTypePages.includes(currentPageLink.ref)) {
    pageType = currentPageLink.ref;
  }

  let staticProps = {
    props: { pageType, dehydratedState, id: contentfulPageId, locale },
  };

  staticProps = await enrichStaticProps(
    staticProps,
    allTranslationKeys,
    locale
  );

  return staticProps;
}) as GetStaticProps;

const Home: FC<{
  id: string;
  pageType: PageType;
}> = ({ id, pageType }) => ReturnPageComponent(id, pageType);

export default Home;
